import React from 'react'
import { ThemeProvider } from 'styled-components'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Theme, GlobalStyle } from './src/styles/global'
import unRegisterWorkers from './src/functions/unRegisterWorker'

import 'firebase/database'

import { Helmet } from 'react-helmet'
import { ModalProvider } from './src/contexts/modal';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <base href="/" />

        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

        <link rel="preload" as="font" href="/fonts/SourceSansPro-Regular.ttf" type="font/ttf" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/SourceSansPro-Black.ttf" type="font/ttf" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/SourceSans3-Regular.ttf" type="font/ttf" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/SourceSans3-Bold.ttf" type="font/ttf" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/Nunito-Regular.ttf" type="font/ttf" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/Nunito-Bold.ttf" type="font/ttf" crossOrigin="anonymous" />

        <script
          type="text/javascript"
          async
          src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ec2d16e6-84d9-4f09-8f25-8cfe4f23e980-loader.js"
        />
      </Helmet>

      <GlobalStyle />

      <ThemeProvider theme={Theme}>
        <ModalProvider>
          {element}
        </ModalProvider>
      </ThemeProvider>
    </>
  )
}

unRegisterWorkers()
