import React, { createContext, useContext, useState } from 'react'

interface ModalContextProps {
  isOpened: boolean
  setIsOpened: (isOpened?: boolean) => void
  isUserLoggedIn: boolean
  setIsUserLoggedIn: (isUserLoggedIn?: boolean) => void
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined)

export const ModalProvider: React.FC = ({ children }) => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)

  return (
    <ModalContext.Provider
      value={{
        isOpened: isModalOpened,
        setIsOpened: (isOpened) => setIsModalOpened(!!isOpened),
        isUserLoggedIn,
        setIsUserLoggedIn: (isUserLoggedIn) =>
          setIsUserLoggedIn(!!isUserLoggedIn)
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export const useModalContext = () => {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }

  return context
}
